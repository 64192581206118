
export default {
  name: `SelectionCard`,
  props: {
    provider: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectProvider() {
      if (!this.provider.available) return
      this.$store.commit(`integrations/setSelectedProvider`, this.provider)
    }
  }
};
